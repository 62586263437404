export default [
  // {
  //   header: 'HITS',
  // },
  {
    title: 'Mercado',
    icon: 'MusicIcon',
    route: 'hit-gestor',
    // ACL
    resource: 'MercadoGestor',
    action: 'read',
  },
]
