export default [
  // {
  //   header: 'Campanhas',
  // },
  {
    title: 'Negociações',
    icon: 'MessageSquareIcon',
    route: 'negociacoes',
    // ACL
    resource: 'Negociacoes',
    action: 'read',
  },
]
