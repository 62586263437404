export default [
  // {
  //   header: 'Gerenciar Hits',
  // },
  {
    title: 'Audições',
    icon: 'GridIcon',
    children: [
      {
        title: 'Criar Audição',
        route: 'campanha-cadastrar',
        // ACL
        resource: 'CampanhasComprador',
        action: 'read',
      },
      {
        title: 'Minhas Audições',
        route: 'campanhas',
        // ACL
        resource: 'CampanhasComprador',
        action: 'read',
      },
      {
        title: 'Pendências',
        route: 'campanha-pendencias',
        // ACL
        resource: 'CampanhasComprador',
        action: 'read',
      },
    ],
  },
]
