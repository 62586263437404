export default [
  // {
  //   header: 'Gerenciar Hits',
  // },
  {
    title: 'Financeiro',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Saldo',
        route: 'financeiro-saldo',
        // ACL
        resource: 'SaldoFinanceiro',
        action: 'read',
      },
    ],
  },
]
