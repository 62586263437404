export default [
  // {
  //   header: 'Campanhas',
  // },
  {
    title: 'Início',
    icon: 'HomeIcon',
    route: 'inicio-compositor',
    // ACL
    resource: 'DashboardCompositor',
    action: 'read',
  },

  {
    title: 'Início',
    icon: 'HomeIcon',
    route: 'inicio-comprador',
    // ACL
    resource: 'DashboardComprador',
    action: 'read',
  },
  {
    title: 'Início',
    icon: 'HomeIcon',
    route: 'inicio-gestor',
    // ACL
    resource: 'DashboardGestao',
    action: 'read',
  },
]
