export default [
  // {
  //   header: 'Gerenciar Hits',
  // },
  {
    title: 'Hits',
    icon: 'MusicIcon',
    children: [
      {
        title: 'Enviar Hit',
        route: 'hit-cadastrar',
        // ACL
        resource: 'HitCadastrar',
        action: 'read',
      },
      {
        title: 'Meus Hits',
        route: 'hit-lista',
        // ACL
        resource: 'HitLista',
        action: 'read',
      },
      {
        title: 'Pendências',
        route: 'hit-pendencias',
        // ACL
        resource: 'HitLista',
        action: 'read',
      },
    ],
  },
]
