export default [
  // {
  //   header: 'Campanhas',
  // },
  {
    title: 'Audições',
    icon: 'GridIcon',
    route: 'campanhas-compositor',
    // ACL
    resource: 'CampanhaCompositor',
    action: 'read',
  },
]
