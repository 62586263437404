/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
import { highlightElement } from 'prismjs'
import inicio from './inicio'
import hit from './hit'
import hitComprador from './hit-comprador'
import hitGestor from './hit-gestor'
import favorito from './favorito'
import playlist from './playlist'
import campanha from './campanha'
import campanhaCompositor from './campanha-compositor'
import negociacaoCompositor from './negociacao'
import aprovacao from './aprovacao'
import usuario from './usuario'
import configuracao from './configuracao'
import financeiro from './financeiro'
import gestaoNegociacao from './gestaoNegociacao'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'

// Array of sections
export default [...inicio, ...hit, ...hitComprador, ...hitGestor, ...campanha, ...favorito, ...playlist, ...campanhaCompositor, ...negociacaoCompositor, ...aprovacao, ...usuario, ...configuracao, ...financeiro, ...gestaoNegociacao]
