export default [
  // {
  //   header: 'Aprovações',
  // },
  {
    title: 'Usuários',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Compositor',
        route: 'usuarios-compositor',
        // ACL
        resource: 'UsuarioCompositorLista',
        action: 'read',
      },
      {
        title: 'Comprador',
        route: 'usuarios-comprador',
        // ACL
        resource: 'UsuarioCompradorLista',
        action: 'read',
      },
    ]
  }
]
