export default [
  {
    title: 'Gestão',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Negociação',
        route: 'gestao-negociacao',
        // ACL
        resource: 'GestaoNegociacao',
        action: 'read',
      }
    ],
  },
]
