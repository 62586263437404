export default [
  // {
  //   header: 'Favoritos',
  // },
  {
    title: 'Favoritos',
    icon: 'StarIcon',
    route: 'hits-favoritos',
    // ACL
    resource: 'CampanhasComprador',
    action: 'read',
  },
]
