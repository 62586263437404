export default [
  // {
  //   header: 'HITS',
  // },
  {
    title: 'Mercado',
    icon: 'MusicIcon',
    route: 'hit-comprador',
    // ACL
    resource: 'MercadoComprador',
    action: 'read',
  },
  {
    title: 'Meus hits',
    icon: 'HeadphonesIcon',
    route: 'meus-hits-comprador',
    // ACL
    resource: 'MercadoComprador',
    action: 'read',
  },
]
