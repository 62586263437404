export default [
  // {
  //   header: 'Aprovações',
  // },
  {
    title: 'Aprovações',
    icon: 'ThumbsUpIcon',
    children: [
      {
        title: 'Hits',
        route: 'aprovacoes-hit',
        // ACL
        resource: 'AprovacaoHit',
        action: 'read',
      },
      {
        title: 'Campanhas',
        route: 'aprovacoes-campanha',
        // ACL
        resource: 'AprovacaoCampanha',
        action: 'read',
      },
    ]
  }
]
